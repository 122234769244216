/**
 * @param {string} text
 * @param {array<function>} validators
 * @return {boolean}
 */
function isValid(text, validators) {
    for (let validator of validators) {
        if (!validator(text)) {
            return false;
        }
    }
    return true
}

/**
 * @param {string} text
 * @return {boolean}
 */
function isEmail(text) {
    return text.match(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i) !== null;
}

/**
 * @param {string} text
 * @return {boolean}
 */
function isNotEmpty(text) {
    return  text !== null && text.length > 0;
}

export {
    isValid,
    isEmail,
    isNotEmpty,
};
