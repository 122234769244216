import React from "react";
import "./Resume.css"
import Button from "./Button";
import Api from "./api.js"

function Resume() {

    function download() {
        Api.getResumeDownloadUrl()
            .then(res => {
                const signedUrl = res.data["signedUrl"];
                const a = document.createElement("a");
                a.href = signedUrl;
                a.download = "justin_pollard_resume.pdf";
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    return (
        <div className="Resume">
            <Button text="Download my resume" onClick={download}/>
        </div>
    );
}

export default Resume;
