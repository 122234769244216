import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from "react-router-dom";
import './App.css';
import Header from "./Header";
import Experience from "./Experience";
import Skills from "./Skills";
import Contact from "./Contact";
import Resume from "./Resume";

function App() {
    return (
        <Router>
            <div className="App">
                <Header/>
                <div className="body">
                    <Switch>
                        <Route path="/skills">
                            <Skills/>
                        </Route>
                        <Route path="/contact">
                            <Contact/>
                        </Route>
                        <Route path="/resume">
                            <Resume/>
                        </Route>
                        <Route path="/">
                            <Experience/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
}

export default App;
