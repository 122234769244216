import React from "react";
import "./Skills.css"

function Skills() {
    return (
        <div className="Skills">
            <div className="header">
                <div className="item">

                </div>
                <div className="item">
                    Skilled
                </div>
                <div className="item">
                    Proficient
                </div>
            </div>
            <div className="table">
                <div className="row">
                    <div className="title">
                        Languages
                    </div>
                    <div className="item">
                        Typescript, Kotlin
                    </div>
                    <div className="item">
                        Javascript, HTML, Python, Swift
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        Frameworks
                    </div>
                    <div className="item">
                        Vue.js (2), Android
                    </div>
                    <div className="item">
                        React Native, React, Expo, iOS
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        Databases
                    </div>
                    <div className="item">
                        PostgreSQL
                    </div>
                    <div className="item">
                        Firestore
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        Testing
                    </div>
                    <div className="item">
                        Jest, Playwright
                    </div>
                    <div className="item">
                        JUnit, Robolectric, MockK
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        Platforms
                    </div>
                    <div className="item">
                        Google Play, Firebase
                    </div>
                    <div className="item">
                        App Store Connect
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        Runtimes
                    </div>
                    <div className="item">

                    </div>
                    <div className="item">
                        Android, Node.js, Browser
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills;
