import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "./Toast.css";

function Toast(props) {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!visible) {
            setVisible(true);
            window.setTimeout(() => {
                props.onTimeout();
            }, 5000)
        }
    })

    return (
        <div className="Toast">
            <div className={`message ${visible ? "visible" : "invisible"}`}>
                {props.message}
            </div>
        </div>
    )
}
Toast.propTypes = {
    message: PropTypes.string.isRequired,
    onTimeout: PropTypes.func.isRequired,
}

export default Toast;
