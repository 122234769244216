import React from "react";
import PropTypes from "prop-types";
import "./Experience.css";
import logo_aven from "./logo-aven-white.png";
import logo_honor from "./logo-honor.png";
import logo_zumper from "./logo-zumper.png";
import logo_bloodhound from "./logo-bloodhound.png";
import Button from "./Button";

function BulletPoint(props) {
    return (
        <div className="BulletPoint">
            <span className="bullet">{`\u25CF`}</span>
            <span className="text">
                {props.text}
            </span>
        </div>
    )
}
BulletPoint.propTypes = {
    text: PropTypes.string.isRequired,
}

function ExperienceItem(props) {

    function openWebsite() {
        window.open(props.website, '_blank');
    }

    function openAndroidLink() {
        window.open(props.androidAppUrl, '_blank');
    }

    return (
        <div className="ExperienceItem">
            <div className="left-column">
                <img
                    className="logo"
                    src={props.logo}
                    alt={props.name}
                />
                <div className="date-ranges">
                    {props.dateRanges}
                </div>
                <div className="buttons">
                    <Button text="Website" onClick={openWebsite}/>
                    {props.androidAppUrl && (
                        <Button text="Android App" onClick={openAndroidLink}/>
                    )}
                </div>
            </div>
            <div className="right-column">
                <p className="description">
                    {props.description}
                </p>
                {props.children}
            </div>
        </div>
    )
}
ExperienceItem.propTypes = {
    logo: PropTypes.string.isRequired,
    dateRanges: PropTypes.arrayOf(DateRange).isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    androidAppUrl: PropTypes.string,
}

function DateRange(props) {
    return (
        <div className="DateRange">
            <div>{props.position}</div>
            <div>{props.dateRange}</div>
        </div>
    );
}
DateRange.propTypes = {
    position: PropTypes.string.isRequired,
    dateRange: PropTypes.string.isRequired,
}

function Experience() {
    return (
        <div className="Experience">
            <ExperienceItem
                logo={logo_aven}
                dateRanges={[
                    <DateRange
                        position="Product Software Engineer"
                        dateRange="3/2021–Present"
                        key="aven-date-range-1"/>,
                ]}
                name="Aven Financial"
                description={
                    "Aven Financial is a national fintech focused on securitized lending via credit card. I joined after the Series B (< 20 FTEs)."
                }
                website="https://www.aven.com"
            >
                <BulletPoint text={
                    "Strengthened customer acquisition & retention by leading development of Aven Advisor: Credit Check App on iOS & Android during growth from 0 to 45k downloads"
                }/>
                <BulletPoint text={
                    "Facilitated 100% CTR improvement & 13% funnel conversion improvement by engineering link click tracking system used in all applicant & customer communication"
                }/>
                <BulletPoint text={
                    "Drove 27% reduction in customer app Time to Interactivity by rearchitecting APIs"
                }/>
                <BulletPoint text={
                    "Increased new loan funnel throughput 100x by debugging & reengineering online notarization website"
                }/>
                <BulletPoint text={
                    "Enabled increased lending capacity by leading development of homeownersassociationamerica.org"
                }/>
                <BulletPoint text="Increased feature delivery speed by building framework to track integration test results"/>
                <BulletPoint text="Mentored 3 junior engineers"/>
            </ExperienceItem>
            <ExperienceItem
                logo={logo_honor}
                dateRanges={[
                    <DateRange
                        position="Staff Software Engineer"
                        dateRange="4/2019–Present"
                        key="honor-date-range-1"/>,
                    <DateRange
                        position="Senior Software Engineer"
                        dateRange="4/2018–4/2019"
                        key="honor-date-range-2"/>,
                ]}
                name="Honor Technology"
                description={
                    "Honor Technology is a national B2B2C "
                    + "tech-enabled home healthcare provider that has raised "
                    + "$255M. I joined after the Series B (< 100 FTEs)."
                }
                website="https://www.joinhonor.com"
                androidAppUrl="https://play.google.com/store/apps/details?id=com.joinhonor.careprovider"
            >
                <BulletPoint text={
                    "Responsible for leading mobile app development that "
                    + "enables Honor’s care provider workforce to deliver "
                    + "in-home care to senior citizens"
                }/>
                <BulletPoint text={
                    "Set QA strategy that improved crash-free user rate "
                    + "from 89% to 99%"
                }/>
                <BulletPoint text={
                    "Increased activity feed click-through rate over 10% "
                    + "by optimizing in-app communication"
                }/>
                <BulletPoint text={
                    "Reduced unplanned appointment cancellation rate by "
                    + "implementing peer-to-peer appointment transfer"
                }/>
                <BulletPoint text={
                    "Eliminated time spent on login related support requests "
                    + "by implementing token-based SMS login and self-serve "
                    + "in-app password reset"
                }/>
                <BulletPoint text="Mentored 1 junior and 3 senior engineers"/>
            </ExperienceItem>
            <ExperienceItem
                logo={logo_zumper}
                dateRanges={[
                    <DateRange
                        position="Lead Android Engineer"
                        dateRange="10/2016–3/2018"
                        key="zumper-date-range-1"/>,
                    <DateRange
                        position="Senior Android Engineer"
                        dateRange="7/2014–10/2016"
                        key="zumper-date-range-2"/>,
                ]}
                name="Zumper"
                description={
                    "Zumper is an international B2C apartment rental "
                    + "marketplace that has raised $143M. I joined after the "
                    + "Series A (< 15 FTEs)."
                }
                website="https://www.zumper.com"
                androidAppUrl="https://play.google.com/store/apps/details?id=com.zumper.rentals"
            >
                <BulletPoint text={
                    "Responsible for Zumper and PadMapper apartment rental "
                    + "Android apps installed by over 1 million users"
                }/>
                <BulletPoint text={
                    "Improved conversion rate over 80% by developing A/B "
                    + "testing program and culture embraced by the mobile "
                    + "product team"
                }/>
                <BulletPoint text={
                    "Increased Zumper’s rating from 3.5 to 4.5 stars across "
                    + "nearly 20,000 ratings by optimizing Google Play Store "
                    + "review request flow"
                }/>
                <BulletPoint text={
                    "Acquired 75,000+ new Android users by negotiating with "
                    + "Google Play Store team to feature Zumper and PadMapper "
                    + "apps 4 times"
                }/>
                <BulletPoint text={
                    "Improved testability and maintainability by "
                    + "re-architecting Android app with Dagger 2 dependency "
                    + "injection library"
                }/>
                <BulletPoint text={
                    "Gained early access to Google technologies by "
                    + "fostering relationships with Google Play and Google "
                    + "Sand Hill Program teams"
                }/>
            </ExperienceItem>
            <ExperienceItem
                logo={logo_bloodhound}
                dateRanges={[
                    <DateRange
                        position="Android Engineer"
                        dateRange="5/2013–6/2014"
                        key="bloodhound-date-range-1"/>,
                    <DateRange
                        position="Android Intern"
                        dateRange="2/2013–5/2013"
                        key="bloodhound-date-range-2"/>,
                ]}
                name="Bloodhound"
                description={
                    "Bloodhound was a B2B mobile CRM software for "
                    + "event marketers that raised $5M. I joined after the "
                    + "Series A (< 15 FTEs)."
                }
                website="https://web.archive.org/web/20140702115322/https://bloodhound.com/"
            >
                <BulletPoint text={
                    "Responsible for Android app that served over 1000 "
                    + "business professionals in the event marketing space"
                }/>
                <BulletPoint text={
                    "Pioneered novel features leveraging technology only "
                    + "available on the Android platform"
                }/>
                <BulletPoint text={
                    "Developed and implemented customer discovery strategy "
                    + "in order to understand pain points and inform product "
                    + "development roadmap"
                }/>
            </ExperienceItem>
        </div>
    );
}

export default Experience;
