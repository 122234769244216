import React from "react";
import "./Header.css"
import {Link, useLocation} from "react-router-dom";
import headshot from "./headshot.png"

function Header() {

    const location = useLocation();

    function isExperience() {
        return location.pathname === "/";
    }

    function isSkills() {
        return location.pathname === "/skills";
    }

    function isContact() {
        return location.pathname === "/contact";
    }

    function isResume() {
        return location.pathname === "/resume";
    }

    return (
        <header className="Header">
            <img src={headshot}/>
            <h1 className="greeting">
                Hi, I'm Justin Pollard
            </h1>
            <div className="menu">
                <Link to="/"
                    className={
                        `menu-item ${isExperience() ? "selected" : ""}`
                    }>
                    Experience
                </Link>
                <Link to="/skills"
                      className={
                          `menu-item ${isSkills() ? "selected" : ""}`
                      }>
                    Skills
                </Link>
                <Link to="/contact"
                      className={
                          `menu-item ${isContact() ? "selected" : ""}`
                      }>
                    Contact
                </Link>
                <Link to="/resume"
                      className={
                          `menu-item ${isResume() ? "selected" : ""}`
                      }>
                    Resume
                </Link>
            </div>
        </header>
    )
}

export default Header;
