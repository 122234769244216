import React from "react";
import PropTypes from "prop-types";
import "./Button.css"

function Button(props) {
    return (
        <div className="Button" onClick={props.onClick}>
            {props.text}
        </div>
    );
}
Button.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default Button;
