import React from "react";
import PropTypes from "prop-types";
import "./ErrorMessage.css"

function ErrorMessage(props) {
    return (
        <div className="ErrorMessage">
            {props.message}
        </div>
    );
}
ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
}

export default ErrorMessage;
