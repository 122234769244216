import firebase from "firebase/app";
import "firebase/firebase-firestore"
import "firebase/firebase-functions"

function config() {
    if (window.location.hostname === "justincpollard.com") {
        return {
            apiKey: "AIzaSyB-koEr_vvHquIPax2DmjLhni5B1K6b-_8",
            authDomain: "justincpollard-216207.firebaseapp.com",
            databaseURL: "https://justincpollard-216207.firebaseio.com",
            projectId: "justincpollard-216207",
            storageBucket: "justincpollard-216207.appspot.com",
            messagingSenderId: "1083659185930",
            appId: "1:1083659185930:web:ddfaa76c0d18a8e86e14f3",
            measurementId: "G-TS1HP39XMF"
        };
    }
    return {
        apiKey: "AIzaSyB8TH2DnP91ZXYsjNm83Yavbq5lnabQRBo",
        authDomain: "justincpollard-dev.firebaseapp.com",
        databaseURL: "https://justincpollard-dev.firebaseio.com",
        projectId: "justincpollard-dev",
        storageBucket: "justincpollard-dev.appspot.com",
        messagingSenderId: "264488900704",
        appId: "1:264488900704:web:dda185f5645a5157ff4b05",
        measurementId: "G-SCR5FWCL8R"
    };
}

function app() {
    let firebaseApp;
    if (firebase.apps.length > 0) {
        firebaseApp = firebase.apps[0];
    } else {
        firebaseApp = firebase.initializeApp(config());
    }
    return firebaseApp;
}

function functions() {
    return app().functions();
}

class Api {

    /**
     * @return {firebase.firestore}
     */
    static firestore() {
        return app().firestore();
    }

    /**
     * @return {Promise<firebase.functions.HttpsCallableResult>}
     */
    static getResumeDownloadUrl() {
        return functions().httpsCallable("getResumeDownloadUrl")();
    }
}

export default Api;
