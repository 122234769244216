import React, {useState} from "react";
import Api from "./api"
import "./Contact.css"
import Button from "./Button";
import ErrorMessage from "./ErrorMessage";
import {isValid, isEmail, isNotEmpty} from "./validators";
import Toast from "./Toast";

function Contact() {

    const [validEmail, setValidEmail] = useState(true);
    const [validMessage, setValidMessage] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    function onSubmit() {
        const emailField = document.getElementById("email");
        const messageField = document.getElementById("message");
        const email = emailField.value;
        const message = messageField.value;

        const isEmailValid = isValid(email, [isEmail]);
        const isMessageValid = isValid(message, [isNotEmpty]);

        setValidEmail(isEmailValid);
        setValidMessage(isMessageValid);

        if (!isEmailValid || !isMessageValid) {
            return;
        }

        Api.firestore().collection("mail")
            .add({
                to: "justincpollard@gmail.com",
                replyTo: email,
                message: {
                    subject: "Contact from justincpollard.com",
                    text: `${message} \n\n ${email}`,
                }
            })
            .then(() => {
                emailField.value = emailField.defaultValue;
                messageField.value = messageField.defaultValue;
                setSuccess(true);
            })
            .catch(err => {
                console.log(err.message);
                setError(err);
            });
    }

    return (
        <div className="Contact">
            <div className="email">
                <input
                    type="email"
                    id="email"
                    className={validEmail ? "" : "error"}
                    placeholder="Your email address"
                    onChange={() => setValidEmail(true)}
                />
                <textarea
                    id="message"
                    className={validMessage ? "" : "error"}
                    placeholder="Your message"
                    onChange={() => setValidMessage(true)}
                />
                {error && (
                    <ErrorMessage message={
                        "Sorry, there was an error. Please make sure you're "
                        + "connected to the Internet and try again."
                    }/>
                )}
                <Button text="Send email" onClick={onSubmit}/>
            </div>
            {success && (
                <Toast
                    message="Email sent!"
                    onTimeout={() => setSuccess(false)}/>
            )}
        </div>
    )
}

export default Contact;
